.LoginPage {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* box-shadow: 0 0 0.45rem rgba(0, 0, 0, .45); */
    padding: 50px 0;
    background-color: #fff;
}

.LoginPage a {
    color: #027e83;
}

.LoginPage :global .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #027e83;
    border-color: #027e83;
}

.LoginPage :global .ant-checkbox-inner:hover {
    background-color: #07abb1 !important;
    border-color: #07abb1 !important;
}

.LoginCenter {
    width: 400px;

}

.LoginLogo {
    text-align: center;
}

.LoginLogo>img {
    width: 150px;
    vertical-align: middle;
}

.LoginLogo>span {
    font-size: 36px;
    font-weight: 800;
}

.loginForms {
    padding: 20px;

}

.loginForms .loginInput {
    border-radius: 20px;
}

.loginForms .ant-input-prefix {
    color: #999;
}

.loginForms input {
    height: 30px;
}

.loginButton {
    display: flex;
    justify-content: space-evenly;
}

.loginButton button:hover {
    background: #05a3a8 !important;
}


.gotoRegister {
    text-align: left;
}

.loginSumit {
    text-align: center;
    border-radius: 6px;
    background: #027e83;
    height: 40px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
}

.fromFooter {
    padding: 0 5px 10px 5px;
    display: flex;
    justify-content: space-between;
}

.fromFooter button {
    text-align: center;
    height: 4rem;
    color: #027e83;
}

.loginModalFooter {
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
}

.loginModalFooter button {
    border-radius: 40px;
    width: 40%;
}