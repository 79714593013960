.MRegister {
  /* display: flex;
  height: 95vh;
  width: 100%;
  justify-content: center;
  align-items: center; */
}

.body {
  max-width: 500px;
  width: 100%;
}

.img {
  text-align: center;
}

.img>img {
  width: 100px;
}

.loginButton {
  display: flex;
  justify-content: space-around;
}



.isRegister {
  text-align: center;
}

.isRegister h2 {
  color: #87d068;
}

.isRegister p {
  color: #87d068;
}


.loginForm :global .ant-input-affix-wrapper {
  border-radius: 50px;
}

.loginForm :global .ant-input-lg {
  border-radius: 50px;
}

.loginForm button {
  border-radius: 50px !important;
}