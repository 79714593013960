.StudentStore {
    padding: 20px 100px;
    max-width: 1500px;
    min-width: 1100px;
    margin: 0 auto;
}

.StudentStoreCol {
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 10px;
}

.StudentStoreRow {
    border: 1px solid #f0f0f0;
}

.StudentStoreRowCol {
    background-color: #f9f9f9 !important;
}

.StudentStoreRowCol2 {
    background-color: #fff;
}

.StudentStoreListsTitle {
    display: flex !important;
    background-size: 100%;
    height: 260px;
    border-radius: 10px;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
}

.StudentStoreListsTitle img {
    border-radius: 10px;
}

.StudentStore img {
    height: 200px;
    width: 100%;
}

.PaySubPaice {
    font-size: 24px;
    color: red;
    font-weight: 700;
    text-align: center;
}

.isBoxAll {
    display: flex;
    align-items: center;
    color: #999;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
}

.isBoxAll span {
    margin: 0 10px;
}

.unBoxAll {
    color: #333;
}

.StudentStoreListsName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 20px;
    min-height: 20px;
    max-height: 40px;
    margin: 8px 0;
    font-size: 16px;
    font-weight: 600;
}

.StudentStoreListsSub {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #545c63;
    line-height: 20px;
    height: 40px;
    margin: 15px 0;
    padding: 0 8px;
}

.StudentStoreListsSubVideo {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #545c63;
    line-height: 20px;
    height: 20px;
    margin: 10px 0;
    padding: 0 8px;
}

.StudentStoreListsSubSub {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #545c63;
    line-height: 30px;
    height: 60px;
    margin: 15px 0;
    padding: 0 8px;
}

.labelList {
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 3px 5px;
    margin: 5px;
}

.StudentStoreListsButton {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    height: 2rem;
}

.StudentStoreListsButton button {
    background-color: #e1251b;
    color: #fff;
    width: 100%;
    font-weight: 600;
    border-radius: 50px;
    border: none;
    margin-right: 20px;
}

.StudentStoreListsButton button:last-child {
    margin-right: 0;
}

.StudentStoreListsButton button:hover {
    color: #fff !important;
    border: none;
}

.view {
    color: #1677ff;
}




.StoreBookList img {
    width: 24px;
    vertical-align: middle;
}

.StoreBookList {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #e4e7ed;
    padding: 10px;
    margin-top: 10px;
    color: #606266;
    font-size: 14px;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
}


.PayTitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PayTitleTitle {
    font-size: 22px;
    font-weight: 700;
}

.PayTitleSub {
    font-size: 16px;
    font-weight: 600;
}

.PayTitle img {
    width: 80px;
    /* height: 80px; */
}

.PayCenater {
    /* background-color: rgb(0, 200, 0); */
    text-align: center;
    padding: 26px;
    border-radius: 20px;
    position: relative;
}

.PayCenaterMen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    /* 设置蒙版的颜色和透明度 */
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 200, 0);
    font-weight: 600;
    font-size: 16px;
}

.PayCenaterMen img {
    height: 40px;
}

.PayFooter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PayFooterTitle {
    font-size: 18px;
    font-weight: 700;
}

.PayFooterSub {
    font-size: 12px;
    font-weight: 600;
    color: #999;
}

.PayFooter img {
    width: 50px;
    /* height: 80px; */
}

.Pagination {
    text-align: center;
    padding: 20px;
}

.gotoModalP span {
    color: #e1251b;
}



.ButtonRight {
    display: inline-block;
    width: 140px;
    height: 32px;
    background-color: antiquewhite;
    position: relative;
    text-align: center;
    line-height: 32px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 0 30px 30px 0;
}

/* .ButtonRight img {
    width: 20px !important;
    height: 20px !important;
    vertical-align: sub;
} */

.imgVips {
    height: 20px !important;
    width: 20px !important;
    vertical-align: sub;
    margin-right: 5px;
}

.PriceButton {
    display: flex;
    height: 4rem;
    align-items: center;

}

.PriceButton div {
    width: 100%;

}

.ButtonLeft {
    display: inline-block;
    width: 140px;
    height: 32px;
    background-color: #e1251b;
    position: relative;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-weight: 600;
    border-radius: 30px 0 0 30px;
}

.ButtonRight::before {
    content: '';
    height: 0px;
    width: 0px;
    position: absolute;
    left: -32px;
    top: -32px;
    border-top: 32px solid rgba(0, 0, 0, 0);
    border-left: 32px solid rgba(0, 0, 0, 0);
    border-right: 32px solid rgba(0, 0, 0, 0);
    border-bottom: 32px solid antiquewhite;
}

.ButtonLeft ::after {
    content: '';
    height: 0px;
    width: 0px;
    position: absolute;
    right: -32px;
    top: -32px;
    border-top: 32px solid rgba(0, 0, 0, 0);
    border-left: 32px solid rgba(0, 0, 0, 0);
    border-right: 32px solid rgba(0, 0, 0, 0);
    border-bottom: 32px solid #e1251b;
}

.StudentStoreSearch {
    padding: 0 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SeachSelectLeft :global .ant-select-selector {
    border-radius: 6px 0 0 6px;
    background-color: #f0f0f0 !important;
}

.SeachSelectRight :global .ant-select-selector {
    border-radius: 0 6px 6px 0;
    background-color: #f0f0f0 !important;
}

.SeachInput :global .ant-input-affix-wrapper:not(:last-child) {
    border-start-start-radius: 0px !important;
    border-end-start-radius: 0px !important;
}

/* .StudentStoreSearch :global .ant-select-selector {
    border-radius: 6px 0 0 6px;
    background-color: #f0f0f0 !important;
} */

/* 
.StudentStoreSearch :global .ant-input-group-wrapper {
    padding: 10px;

} */

/* .StudentStoreSearch :global .ant-input-group-addon {
    border-radius: 20px;
}

.StudentStoreSearch :global .ant-input-group-addon .ant-input-search-button {
    border-start-end-radius: 20px !important;
    border-end-end-radius: 20px !important;
    width: 50px;
}

.StudentStoreSearch :global .ant-select-show-arrow:hover .ant-select-selector {
    border-color: none;
    border-radius: 40px 0 0 40px;
} */

/* 视频 */
/* .BookVideo {
    height: 95vh;
    padding-bottom: 15vh;
    overflow-y: auto;
    background-color: #f0f0f0;
    border-radius: 10px;
} */

.BookVideoData {
    margin: 10px;
    column-count: 1;
    column-gap: 10px;
}

.bookVidoList {
    margin-bottom: 10px;
}

.bookVidoList video {
    width: 100%;
    height: 100%;
    border-radius: 0 0 10px 10px;
}

.bookVidoListSpan {
    display: inline-block;
    width: 100%;
    text-align: center;
    border-radius: 10px 10px 0 0;
    padding: 10px 0;
    font-weight: 600;
    background: rgb(2, 126, 131);
    color: #fff;

}

.openVip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px;
    border-radius: 10px;
    width: 400px;
    background-color: antiquewhite;
}