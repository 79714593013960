.noData {
  display: inline-block;
  padding: 10px 20px;
  /* border: 2px dashed red; */
  border-radius: 10px;
  margin: 20px;
  text-align: center;
  color: #e35454;

}

.NoDataPage {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.noDataLogo img {
  height: 50px;
  border-radius: 50px;
}

.noDataText {
  font-style: italic;
  font-weight: bold;
}

.noDataSuccess {
  border-color: #67c23a;
  color: #67c23a;
  text-align: center;
}