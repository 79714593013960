.PayTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PayTitleTitle {
  font-size: 22px;
  font-weight: 700;
}

.PayTitleSub {
  font-size: 16px;
  font-weight: 600;
}

.PayTitle img {
  width: 80px;
  /* height: 80px; */
}

.PayCenater {
  /* background-color: rgb(0, 200, 0); */
  text-align: center;
  padding: 26px;
  border-radius: 20px;
  position: relative;
}

.PayCenaterMen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  /* 设置蒙版的颜色和透明度 */
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 200, 0);
  font-weight: 600;
  font-size: 16px;
}

.PayCenaterMen img {
  height: 40px;
}

.PayFooter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PayFooterTitle {
  font-size: 18px;
  font-weight: 700;
}

.PayFooterSub {
  font-size: 12px;
  font-weight: 600;
  color: #999;
}

.PayFooter img {
  width: 50px;
  /* height: 80px; */
}

.PaySubPaice {
  font-size: 24px;
  color: red;
  font-weight: 700;
  text-align: center;
}

.gotoModalP span {
  color: #e1251b;
}