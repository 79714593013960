.PermissionPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 100%;
}

.PermissionPageTitle {
    text-align: center;
}

.PermissionPage img {
    width: 200px;
}