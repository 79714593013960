.RegisterPage {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 0 0.45rem rgba(0, 0, 0, .45); */
    padding: 50px 0;
    /* background-color: #f0f0f0; */
}

.isRegister {
    text-align: center;
}

.isRegister h2 {
    color: #87d068;
}

.isRegister p {
    color: #87d068;
}

.body {
    width: 530px;
    /* width: 100%; */
}

.img {

    text-align: center;
}

.img>img {
    width: 150px;
    vertical-align: middle;
}

.loginButton {
    display: flex;
    justify-content: space-around;
}

.formCode {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formCode input {
    max-width: 70%;
}

.loginSumit {
    text-align: center;
    border-radius: 6px;
    background: #027e83;
    height: 40px;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
}

.loginButton button:hover {
    background: #05a3a8 !important;
}

.fromFooter {
    padding: 0 5px 10px 5px;
    display: flex;
    justify-content: space-between;
}

.fromFooter button {
    text-align: center;
    height: 4rem;
    background-color: #027e83;
    color: #fff;
}