.Header {
    position: relative;
    z-index: 10;
    max-width: 100%;
    /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02); */
}



.HeaderMenu a {
    color: var(--menu-item-selected-color) !important;
}

.HeaderMenu a:hover {
    color: var(--menu-item-hover-color) !important;
}


.HeaderMenu :global .ant-menu-item:hover::after {
    border-bottom-color: var(--menu-item-hover-color) !important;
}

.HeaderMenu :global .ant-menu-item-selected::after {
    border-bottom: 2px solid var(--ant-menu-item-selected) !important;
}

.HeaderMenu :global .ant-menu-item-selected a {
    color: var(--ant-menu-item-selected) !important;
    font-weight: 600;
}



.Content {
    /* height: 95vh; */
    background-color: #fff;
    overflow-y: auto;
}

.Logo {
    height: 50px;
    vertical-align: middle;

}

/* div::-webkit-scrollbar {
    display: none;
} */

.HomeSearch {
    height: 64px;
    display: flex;
    align-items: center;
    max-width: 200px;
}

.HomeSearch>span input {
    border-radius: 20px !important;
}

.HomeSearch>span>span button {
    border-radius: 20px !important;
    margin-left: 10px;
}