.headers {
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  /* color: #fff; */
}

.MindexJpg {
  height: 100vh;
  background: url('./../../Static/images/Mindex.jpg');
  background-size: 103%;
}

.Header {

  position: relative;
  z-index: 10;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

.logoDiv {
  height: 64px;
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  border-radius: 20px;
}

.loginRes {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 20px;
  height: 30px;
  align-items: center;
}

.ResButton {
  /* border-radius: 10px 0 0 10px; */
  border-right: 1px solid #fff;
  /* height: 30px; */
  padding: 0 15px;
}

.loginButton {
  padding: 0 15px;

  /* border-radius: 0 10px 10px 0; */

}