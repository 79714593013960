.MLogin {
  display: flex;
  height: 93vh;
  justify-content: center;
  align-items: center;

}

.LoginCenter {
  max-width: 500px;
  width: 100%;
  margin-top: -10vh;

}

.LoginLogo {
  text-align: center;
}

.LoginLogo>img {
  width: 150px;
}

.loginForms {
  padding: 20px;

}

.loginForms .loginInput {
  border-radius: 20px;
}

.loginForms .ant-input-prefix {
  color: #999;
}

.loginForms input {
  height: 30px;
}

.loginButton {
  display: flex;
  justify-content: space-evenly;
}

.gotoRegister {
  text-align: left;
}

.loginSumit {
  text-align: center;
  border-radius: 50px;
  background: #409eff;
  height: 40px;
  width: 160px;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}



.loginModalFooter {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
}

.loginModalFooter button {
  border-radius: 40px;
  width: 40%;
}

.loginForm :global .ant-input-affix-wrapper {
  border-radius: 50px;
}