.StudentHomePage {
  height: 95vh;
  overflow-y: auto;
  padding-bottom: 5vh;
}


.StudentHeader {
  padding: 0 50px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  color: #fff;
}

.HeaderLeft {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.HeaderImages {
  display: flex;
}



.HeaderRight button {
  background-color: rgba(0, 0, 0, 0.0);
  border: none;
  height: 3rem;
  min-width: 120px;
  margin-left: 20px;
  font-weight: 600;
  box-shadow: none;
  color: #fff;
}



.HeaderLogo {
  height: 55px;
  vertical-align: bottom;
  border-radius: 100px;
}


.StudentSub {
  color: #999;
  font-weight: 600;
}

.StudentHeaderSub {
  background-size: 20%;
  /* box-shadow: 2px 2px 10px #909090; */
}

.HeaderSubBox {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

}

.HeaderSubBox>div {
  width: 50%
}

.HeaderSubBoxLeft {
  width: 100%;
  color: #027e83;
  background-color: #fff;
  border-right: #027e83 4px solid;
}

.HeaderSubBoxLeft img {
  max-width: 100%;
  height: 440px;
}

.IndexImg1 {
  /* border-top: 4px solid #027e83; */
  text-align: center;
}



.IndexList {
  display: flex;
  padding: 20px 80px;
  justify-content: space-between;
  align-items: center;
}

.indexListS {
  /* display: inline-block; */
  padding: 10px 30px;
  background-color: #027e83;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  border-radius: 15px;
}

.StudentFooter {
  background: #027e83;
  color: #FFF;
  line-height: 3rem;
}

.StudentFooter button {
  color: #FFF;

}

.StudentFooters {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StudentFooter1 {
  background: #fff;
  color: #027e83;
  border-bottom: 4px solid #027e83;
}

.StudentFooters1 {
  margin: 0 auto;
  /* padding: 1px 70px 1px 50px; */

}

.CarouselIndex :global .slick-dots li button {
  background-color: #000 !important;
}

.CarouselIndex :global .slick-dots li button::after {
  /* background-color: #000; */
}

/* .StudentFooters1List {
  display: flex;
  justify-content: space-between;
}

.StudentFooters1List>div {
  cursor: pointer;
}

.StudentFooters1List>div>p {
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
}

.StudentFooters1List>div>p:first-child {
  font-weight: 700;
  margin-bottom: -20px;
} */
.Introduce {
  text-align: center;
}

.Introduce video {
  width: 600px;
}